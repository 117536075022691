import { Link } from "gatsby"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { GetMediaItems } from "../../contexts/actions"
import { AppContext, AppContextProviderValue } from "../../contexts/AppContext"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useMediaItems } from "../../hooks/useMediaItems"
import { mediaType } from "../../interfaces/MediaType"
import LoadingIndicator from "../LoadingIndicator"
import { CardBase } from "./CardBase"
import Stack from "@components/Stack/Stack"
import { getRatingIcon } from "../../utils/getRatingIcon"
import { getIcon } from "../../utils/getMediaItemIcon"
import { MediaItemCardBody } from "./MediaItemCardBody"

const Card = styled(CardBase)``
const CardHeading = styled.h2``

const List = styled.ul`
  list-style: none;
  margin: 0;
`

const ListItemInner = styled.div`
  border-bottom: 1px solid var(--color-border);
  padding: calc(0.5rem * var(--multiplier)) 0;
`

const ListItemHeaderRow = styled.div`
  display: flex;
  h3 {
    padding-left: 0.5rem;
  }
`

export const MediaItemCard = () => {
  const { mediaItems, isLoading, hasError } = useMediaItems()

  return (
    <Card>
      <Stack topMarginSize={"m"}>
        <CardHeading>My latest watchings, playings and readings</CardHeading>
        {isLoading && <LoadingIndicator />}
        {hasError ? (
          <p>Error loading items</p>
        ) : (
          mediaItems && (
            <List>
              {mediaItems.slice(0, 5).map((item, i) => (
                <li key={item.id}>
                  <ListItemInner>
                    <ListItemHeaderRow>
                      <span
                        aria-label={
                          (item.mediaType !== undefined
                            ? `${mediaType[item.mediaType]} `
                            : `media`) + "item"
                        }
                      >
                        {getIcon(item.mediaType)}
                      </span>
                      <h3>{item.name}</h3>
                    </ListItemHeaderRow>
                    <MediaItemCardBody item={item} />
                  </ListItemInner>
                </li>
              ))}
            </List>
          )
        )}
        <Link to="/media-journal">See my "Media Journal"</Link>
      </Stack>
    </Card>
  )
}
