import { mediaType } from "../interfaces/MediaType"

export const getIcon = (mediaTypeKey: keyof typeof mediaType): string => {
  switch (mediaTypeKey) {
    case "Book":
      return "📖"
    case "Movie":
      return "🎬"
    case "TvSeries":
      return "📺"
    case "VideoGame":
      return "🎮"
    default:
      return "⭐"
  }
}
