import React from 'react';
import styled from 'styled-components';
import { BoxButton } from '../Button/BoxButton';

const FilterList = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-left: 0;
    flex-wrap: wrap;
    flex-direction: column;

    li:last-child {
        margin-right: 0;
    }
    @media (min-width: 768px) {
        flex-direction: row;
    }
    padding: calc(1rem * var(--multiplier));
`

const FilterListItem = styled.li`
    flex: 1;
    width: 100%;
    @media (min-width: 768px) {
        flex: 0;
        margin-right: 1rem;
        width: auto;
    }
`

const FilterButton = styled(BoxButton)`
        width: 100%;
        white-space: nowrap;
    @media (min-width: 768px) {
        width: auto;
    }
`

const ArticleFilter = (props) => {
    const categories = props.categories;
    const activeCat = props.activeCategory;

    const handleChangeFilter = category => {
        props.updateActiveFilter(category);
    }

    return (
        <FilterList>
            <FilterListItem>
                <FilterButton isActive={activeCat === 'all'} onClick={() => handleChangeFilter("all")}>All</FilterButton>
            </FilterListItem>
            {categories.map((cat, i) => (
                <FilterListItem key={i}>
                    <FilterButton isActive={activeCat === cat} onClick={() => handleChangeFilter(cat)}>{cat}</FilterButton>
                </FilterListItem>
            ))}
        </FilterList>
    )
}

export default ArticleFilter;