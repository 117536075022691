import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useState } from "react";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';
import styled from 'styled-components';
import Arrow from "../../images/icons/arrow.svg";
import ArrowLeft from "../../images/icons/arrow-left.svg";


const StyledSlider = styled(Slider)`
    .slick-active {
        .hero-carousel__image {
            filter: none;
            animation-name: example;
            animation-duration: 4s;
        }
    }
    @keyframes example {
        0%   {transform: scale(0.9)}
        25%  {background-color: yellow;}
        50%  {background-color: blue;}
        100% {transform: scale(1)}
    }
`

const ArrowEl = styled.button`
    &:before {
        content: '';
    }
    position: absolute;
    top: auto;
    bottom: 1.5rem;
    left: auto;
    width: auto;
    @media (min-width: 768px) {
        bottom: 3rem;
    }
    svg {
        position: relative;
    }
    &.slick-prev   {
        z-index: 1;
        left: 25%;
        @media (min-width: 768px) {
            right: 10rem;
            left: auto;
        }
    }
    &.slick-next {
        right: 25%;
        @media (min-width: 768px) {
            right: 4rem;
        }
    }
`


const ArrowButtonPrev = ({ className, onClick }) => (
    <ArrowEl className={className} onClick={onClick}>
        <ArrowLeft />
    </ArrowEl>
);

const ArrowButtonNext = ({ className, onClick }) => (
    <ArrowEl className={className} onClick={onClick}>
        <Arrow />
    </ArrowEl>
);


const SliderContainer = styled.div`
    position: relative;
    width: 100%;
`

const CarouselIndex = styled.div`
    display: inline-block;
    padding: 0.25rem 0.5rem;
    background-color: black;
    border-radius: 0.25rem;
    color: var(--color-on-dark);
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
`

const CurrentIndex = styled.span`
    font-weight: bold;
`

const ImagesLength = styled.span`

`


const Image = styled(Img)`
    filter: grayscale();
`

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleIndexChange = (i) => {
        setActiveIndex(i);
    }

    return (<StaticQuery

        query={graphql`query {
           grayScale: allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
              nodes {
                 id
                 relativePath
                 childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1400,  maxHeight: 800, quality: 60, grayscale: true) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                    }
                 }
              }
           }
           color: allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
            nodes {
               id
               relativePath
               childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 800, maxHeight: 600, grayscale: true) {
                      # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                      ...GatsbyImageSharpFluid_noBase64
                  }
               }
            }
         }
        }`}


        render={data => {
            const images = data.grayScale.nodes;
            var settings = {
                dots: false,
                infinite: true,
                speed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                accessibility: true,
                autoplay: false,
                fade: true,
                nextArrow: <ArrowButtonNext />,
                prevArrow: <ArrowButtonPrev />,
                afterChange: handleIndexChange
            };
            return (
                <SliderContainer>
                    <CarouselIndex>
                        <CurrentIndex>
                            {activeIndex + 1}
                        </CurrentIndex>
                        <ImagesLength>
                            &nbsp;/ {images.length}
                        </ImagesLength>
                    </CarouselIndex>
                    <StyledSlider {...settings}>
                        {images.map((image, i) => (
                            <Image
                                key={i}
                                fluid={{ ...image.childImageSharp.fluid }}
                                imgStyle={{ objectFit: 'contain' }}
                                className="hero-carousel__image"
                                alt={"Hey"} />
                        ))}
                    </StyledSlider>
                </SliderContainer>
            );
        }}
    />)
}

export default Carousel;