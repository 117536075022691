import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import TwitterCard from "./Cards/TwitterCard"
import FeaturedCard from "./Cards/FeaturedCard"
import { MediaItemCard } from "./Cards/MediaItemsCard"
import { StaticQuery, graphql } from "gatsby"
import Container from "./Container"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Heading = styled.h2`
  text-align: center;
  grid-area: heading;
`

const Wrapper = styled(Container)`
  display: grid;
  gap: var(--gap);
  width: 100%;
  grid-template:
    [row1-start] "heading" [row1-end]
    [row2-start] "insta" [row2-end]
    [row3-start] "featured" [row3-end]
    [row4-start] "twitter" [row4-end]
    [row5-start] "latest" [row5-end];
  grid-template-columns: minmax(300px, 1fr);

  @media (min-width: 768px) {
    grid-template:
      [row1-start] "heading heading" [row1-end]
      [row2-start] "latest featured" [row2-end]
      [row3-start] "twitter insta" [row3-end];
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
  @media (min-width: 1200px) {
    grid-template:
      [row1-start] "heading heading heading" [row1-end]
      [row2-start] "featured twitter latest" [row2-end];
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
`

const SectionOuter = styled.section`
  background-size: contain;
  background-repeat: repeat-y;
`

const FeaturedCardWrapper = styled.div`
  grid-area: featured;
`

const TwitterCardWrapper = styled.div`
  grid-area: twitter;
`

const LatestBlogCardWrapper = styled.div`
  grid-area: latest;
`

const IntroSection = () => {
  const headingRef = useRef(null)
  const cardsRef = useRef([])

  useEffect(() => {
    gsap.from(headingRef.current, {
      autoAlpha: 0,
      y: "20px",
      scrollTrigger: {
        trigger: headingRef.current,
        start: "top 90%",
        id: "heading",
      },
    })
  }, [headingRef])

  useEffect(() => {
    let delay = 0
    cardsRef.current.forEach(card => {
      gsap.from(card, {
        autoAlpha: 0,
        delay: delay,
        y: "20px",
        scrollTrigger: {
          trigger: card,
          start: "top 90%",
          id: "cards",
        },
      })
      delay += 0.25
    })
  }, [cardsRef, headingRef])

  return (
    <StaticQuery
      query={graphql`
        {
          allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
              id
              timeToRead
              excerpt(pruneLength: 200, truncate: true)
              frontmatter {
                date(formatString: "dddd DD MMMM YYYY")
                keywords
                contentType
                pageContentType
                codePenId
                isCodePen
                isFeatured
                title
                image {
                  childImageSharp {
                    fluid(
                      maxWidth: 350
                      maxHeight: 240
                      quality: 60
                      grayscale: false
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const featuredArticle = data.allMdx.nodes
          .filter(article => article.frontmatter.isFeatured)
          .pop()
        const latestArticle = data.allMdx.nodes[0]
        return (
          <SectionOuter>
            <Heading ref={headingRef}>The latest happenings</Heading>
            <Wrapper as="section">
              <FeaturedCardWrapper ref={el => cardsRef.current.push(el)}>
                <FeaturedCard title="Featured Article" {...featuredArticle} />
              </FeaturedCardWrapper>
              <TwitterCardWrapper ref={el => cardsRef.current.push(el)}>
                <MediaItemCard />
              </TwitterCardWrapper>
              <LatestBlogCardWrapper ref={el => cardsRef.current.push(el)}>
                <FeaturedCard title="Latest Article" {...latestArticle} />
              </LatestBlogCardWrapper>
            </Wrapper>
          </SectionOuter>
        )
      }}
    />
  )
}

export default IntroSection
