import React from 'react';
import styled from 'styled-components';
import Container from '../../Container';

const Wrapper = styled.div`
    max-width: 100%;
    width: 100%;
    @media (min-width: 992px) {
        max-width: 55%;
    }
`

export const PartialWidth = ({ props, children }) => (
    <Container>
        <Wrapper>
            {children}
        </Wrapper>
    </Container>
)

export default PartialWidth;
