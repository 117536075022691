import React, { useState, useEffect, useCallback, useRef } from "react"
import { StaticQuery, graphql } from "gatsby"
import Article from "./ArticleCard"
import styled from "styled-components"
import ArticleFilter from "./ArticleFilter"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ArticlesWrapper = styled.div`
  background: url("topography.png");
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 1rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    display: block;
    top: 0;
    position: absolute;
  }
  padding-bottom: calc(5rem * var(--multiplier));
  padding-top: calc(5rem * var(--multiplier));
  @media (min-width: 768px) {
    padding-top: calc(2rem * var(--multiplier));
    padding-bottom: calc(2rem * var(--multiplier));
  }
`

const ArticleList = styled.ul`
  display: grid;
  gap: var(--gap);
  list-style: none;
  grid-template-columns: repeat(12, 1fr);
  margin-left: 0;
  max-width: 1400px;
  margin: auto;
  padding: calc(1rem * var(--multiplier));
`

const ArticleListItem = styled.li`
  grid-column: auto / span 12;
  grid-row: auto / span 1;
  @media (min-width: 768px) {
    grid-column: auto / span 6;
  }

  @media (min-width: 1200px) {
    grid-column: auto / span 4;
  }
`

const ArticleListHeading = styled.h2`
  text-align: center;
  padding-top: 3rem;
`

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
              node {
                timeToRead
                excerpt(pruneLength: 200, truncate: true)
                fields {
                  slug
                  externalThumbnail
                }
                id
                frontmatter {
                  title
                  keywords
                  date(formatString: "MMMM YYYY")
                  contentType
                  image {
                    childImageSharp {
                      fluid(
                        maxWidth: 350
                        maxHeight: 240
                        quality: 60
                        grayscale: false
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          images: allFile {
            nodes {
              id
              relativePath
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 125, height: 125) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      `}
      render={data => {
        const [articleCategories, setArticleCategories] = useState([])
        const [activeCategory, setActiveCategory] = useState("all")
        const [filteredArticles, setFilteredArticles] = useState(null)
        const [gatsbyImageArticles] = useState([])
        const headingRef = useRef(null)
        const filtersRef = useRef(null)
        const articleListRef = useRef(null)
        const cardsRef = useRef([])

        useEffect(() => {
          gsap.from(headingRef.current, {
            autoAlpha: 0,
            y: "20px",
            scrollTrigger: {
              trigger: headingRef.current,
              start: "top bottom-=200px",
            },
          })
          gsap.from(filtersRef.current, {
            autoAlpha: 0,
            y: "20px",
            scrollTrigger: {
              trigger: filtersRef.current,
              start: "top bottom-=200px",
            },
          })
        }, [headingRef, filtersRef])

        useEffect(() => {
          let delay = 0
          cardsRef.current.forEach(card => {
            gsap.from(card, {
              autoAlpha: 0,
              delay: delay,
              y: "20px",
              scrollTrigger: {
                trigger: articleListRef.current,
                start: "top center",
                id: "cards",
              },
            })
            delay += 0.25
          })
        }, [cardsRef])

        const articles = data.allMdx.edges

        const filterArticles = useCallback(() => {
          if (activeCategory === "all") {
            return articles
          } else {
            return articles.filter(
              article => article.node.frontmatter.contentType === activeCategory
            )
          }
        }, [activeCategory, articles])

        useEffect(() => {
          setArticleCategories(allCategories(data.allMdx.edges))
          setFilteredArticles(filterArticles)
        }, [data.allMdx.edges, filterArticles])

        useEffect(() => {
          setFilteredArticles(filterArticles)
        }, [gatsbyImageArticles, filterArticles])

        useEffect(() => {
          setFilteredArticles(filterArticles)
        }, [activeCategory, filterArticles])

        const updateActiveFilter = filterCategory => {
          setActiveCategory(filterCategory)
        }

        const allCategories = allArticleNodes => {
          return [
            ...new Set(
              allArticleNodes.map(({ node }) => node.frontmatter.contentType)
            ),
          ]
        }

        return (
          <section>
            <ArticleListHeading ref={headingRef}>
              Some of my thinking
            </ArticleListHeading>
            <div ref={filtersRef}>
              <ArticleFilter
                categories={articleCategories}
                activeCategory={activeCategory}
                updateActiveFilter={updateActiveFilter}
              />
            </div>
            <ArticlesWrapper>
              <ArticleList ref={articleListRef}>
                {filteredArticles !== null &&
                  filteredArticles.map(({ node }, i) => (
                    <ArticleListItem
                      key={i}
                      ref={el => (cardsRef.current[i] = el)}
                    >
                      <Article
                        id={node.id}
                        to={node.fields.slug}
                        keywords={node.frontmatter.keywords}
                        title={node.frontmatter.title}
                        date={node.frontmatter.date}
                        timeToRead={node.timeToRead}
                        image={node.frontmatter.image}
                        excerpt={node.excerpt}
                      />
                    </ArticleListItem>
                  ))}
              </ArticleList>
            </ArticlesWrapper>
          </section>
        )
      }}
    />
  )
}
