import React from 'react';
import styled from 'styled-components';
import TwitterIcon from "../images/icons/twitter.svg";


const TweetListItem = styled.li`
    margin-bottom: 0;
    small {
        color: var(--color-accent);
    }
`

const Icon = styled(TwitterIcon)`
    width: 2rem;
    display: block;
`

const TweetLink = styled.a`
    border-bottom: 1px solid var(--color-accent);
    display: block;
    padding: calc(0.5rem * var(--multiplier)) 0;
    color: var(--color-body);
    p {
        margin-bottom: calc(0.25rem * var(--multiplier));
    }
    small {
        transition: color 0.25s ease;
    }
    svg 
    path {
            transition: fill 0.25s ease;
    }
    &:hover {
        small {
            color: #000000;
            transition: color 0.25s ease;
        }
        svg path {
            fill: #1EA1F2;
            transition: fill 0.25s ease;

        }
    }
`

const Tweet = ({ tweet }) => {

    return (
        <TweetListItem>
            <TweetLink href={tweet.url} target="_blank" title="External link to Twitter">
                <Icon />
                <small>{tweet.text}</small>
            </TweetLink>
        </TweetListItem>
    )
}

export default Tweet;