import React from "react";
import styled from 'styled-components';
import { CardBase } from './CardBase';
import useTweets from '../../utils/useTweets';
import LoadingIndicator from '../LoadingIndicator';
import Tweet from '../Tweet';

const Card = styled(CardBase)`
`
const CardHeading = styled.h2`

`

const TweetsList = styled.ul`
    list-style: none;
    margin: 0;
`

const TwitterCard = () => {
    const tweets = useTweets();

    return (
        <Card>
            <CardHeading>Latest Tweets</CardHeading>

            {tweets !== null ?
                <TweetsList>
                    {tweets.map((tweet, i) =>
                        <Tweet key={i} tweet={tweet} />
                    )}
                </TweetsList>
                :
                <LoadingIndicator />
            }
        </Card>
    )
}

export default TwitterCard;