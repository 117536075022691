import React from "react"
import ArticleList from "../components/ArticleList/article-list"
import HeroContainer from "../components/Hero/HeroContainer"
import IntroSection from "../components/IntroSection"
import Layout from "../components/layout"
import Stack from "../components/Stack/Stack"

const Home = () => {
  return (
    <Layout>
      <Stack topMarginSize="xxl">
        <HeroContainer />
        <IntroSection />
        <ArticleList />
      </Stack>
    </Layout>
  )
}

export default Home
