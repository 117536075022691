import { MediaItemDto } from "../../types/MediaItemDto"
import { getRatingIcon } from "../../utils/getRatingIcon"
import React from "react"
import Stack from "@components/Stack/Stack"

type MediaItemCardBodyProps = {
  item: MediaItemDto
  toggleDescription?: boolean
}

export const MediaItemCardBody = ({
  item,
  toggleDescription,
}: MediaItemCardBodyProps) => (
  <Stack topMarginSize={"m"}>
    {item.comments && (
      <p>
        <span title="My comments" aria-label="My comments">
          💬
        </span>{" "}
        {item.comments}
      </p>
    )}
    {item.rating && !item.isOnWishList && (
      <p>
        <span title="My rating" aria-label="My rating">
          {getRatingIcon(item.rating)}
        </span>{" "}
        {item.rating} / 10
      </p>
    )}
    {item.isOnWishList && (
      <p>
        <span title="On my wishlist" aria-label="Check icon">
          ✅
        </span>{" "}
        In the backlog
      </p>
    )}
    {item.description && toggleDescription && (
      <p>
        <span title="Description" aria-label="Description">
          🧾
        </span>{" "}
        {item.description}
      </p>
    )}
  </Stack>
)
