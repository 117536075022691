export const AboutMeStack =
    [
        {
            "name": "React",
            "purpose": "React (React/ Gatsby/ NextJS)",
            "logoPath": "logos/logo_react.png",
            "link": "https://reactjs.org/"
        },
        {
            "name": "Angular",
            "purpose": "Angular 2+",
            "logoPath": "logos/logo_angular2.png",
            "link": "https://angular.io/"
        },
        {
            "name": "Contentful",
            "purpose": "Contentful CMS",
            "logoPath": "logos/logo_contentful.png",
            "link": "https://www.contentful.com/"
        },
        {
            "name": "NextJS",
            "purpose": "Front end and Serverless functions",
            "logoPath": "logos/logo_next.png",
            "link": "https://nextjs.org/"
        },
        {
            "name": "Netlify",
            "purpose": "Netlify hosting",
            "logoPath": "logos/logo_netlify.png",
            "link": "https://www.netlify.com/"
        },
        {
            "name": "Styled Components & CSS Variables",
            "purpose": "Styled Components",
            "logoPath": "logos/logo_styled-comp.png",
            "link": "https://styled-components.com/"
        }
    ];
