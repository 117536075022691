import gsap, { TimelineMax } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { AppContext } from "../../contexts/AppContext"
import { openModal } from "../../contexts/AppContext.consts"
import { TextButton } from "../Button/TextButton"
import PartialWidth from "../Layout/PartialWidth/PartialWidth"
import TechStack from "../Post/TechStack"
import Stack from "../Stack/Stack"
import { AboutMeStack } from "./AboutMeStack"
gsap.registerPlugin(CSSPlugin, ScrollToPlugin)

const Wrapper = styled.section`
  width: 100%;
  background: var(--color-light-grey);
  padding: var(--space-xxl) 0;
  height: auto;
  opacity: 1;
`

const AboutMe = ({ isShowingIntro }) => {
  const [animateIn, setAnimateIn] = useState(new TimelineMax({ paused: true }))
  const [animateOut, setAnimateOut] = useState(
    new TimelineMax({ paused: true })
  )
  const { dispatch } = useContext(AppContext)
  let staggerItemRef = useRef([])
  let containerRef = useRef(null)

  useEffect(() => {
    gsap.set(containerRef, {
      padding: 0,
      height: 0,
      autoAlpha: 0,
      marginTop: 0,
    })
  }, [])

  useEffect(() => {
    if (isShowingIntro) {
      setAnimateIn(animateIn =>
        animateIn
          .add("t")
          .to(
            containerRef,
            {
              duration: 0.25,
              padding: "var(--space-xxl) 0",
              height: "auto",
              autoAlpha: 1,
              marginTop: "var(--space-xxl)",
            },
            "t+=0.25"
          )
          .fromTo(
            containerRef,
            { y: -20, autoAlpha: 0 },
            { duration: 0.3, y: 0, autoAlpha: 1 },
            "t+=0.3"
          )
          .fromTo(
            staggerItemRef.current,
            0.5,
            {
              y: -20,
              autoAlpha: 0,
            },
            {
              y: 0,
              autoAlpha: 1,
              stagger: 0.25,
            },
            "t+=0.3"
          )
          .to(
            window,
            {
              duration: 1,
              scrollTo: {
                y: containerRef,
                offsetY: -102,
              },
            },
            "t-=0.1"
          )
      )
      animateIn.play()
    }
    if (isShowingIntro !== null && isShowingIntro === false) {
      setAnimateOut(animateOut =>
        animateOut.add("s").to(containerRef, {
          autoAlpha: 0,
          duration: 0.25,
          padding: 0,
          height: 0,
          marginTop: 0,
        })
      )
      animateOut.play()
    }
  }, [isShowingIntro, animateIn, animateOut])

  return (
    <Wrapper ref={el => (containerRef = el)}>
      <PartialWidth>
        <Stack>
          <h2>A bit more about me</h2>
          <p ref={el => (staggerItemRef.current[0] = el)}>
            With a background in digital marketing and project management I can
            deliver your project smoothly and assist with growing it.
          </p>
          <p ref={el => (staggerItemRef.current[1] = el)}>
            I have worked on a variety of projects including chat bots,
            eCommerce stores, car clubs, insurance systems and more.
          </p>
          <p ref={el => (staggerItemRef.current[2] = el)}>
            If you're interested in bringing your project to life, please{" "}
            <TextButton onClick={() => dispatch({ type: openModal })}>
              {" "}
              get in touch with me
            </TextButton>{" "}
            <span role="img" aria-label="cool-guy">
              😎
            </span>
            .
          </p>
          <div ref={el => (staggerItemRef.current[3] = el)}>
            <TechStack stack={AboutMeStack}></TechStack>
          </div>
        </Stack>
      </PartialWidth>
    </Wrapper>
  )
}

export default AboutMe
