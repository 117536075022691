import { useState, useEffect } from 'react';

export const formattedTweets = tweetsArray => {
    return tweetsArray.map(tweet => (
        {
            date: tweet.created_at,
            text: tweet.full_text,
            url: `https://twitter.com/Scottys88/status/${tweet.id_str}`
        }
    ))
}

function useTweets() {
    const [tweets, setTweets] = useState(null);

    useEffect(() => {
        fetch((`https://swom1b3zr8.execute-api.ap-southeast-2.amazonaws.com/default/getTweets`))
            .then(res => res.json())
            .then(data => {
                const formattedData = formattedTweets(data);
                setTweets(formattedData)
            })
    }, []);

    return tweets;
}

export default useTweets;