import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { CardBase } from "../Cards/CardBase"
import Stack from "../Stack/Stack"
import Img from "gatsby-image"

const ArticleCardElement = styled(CardBase)``

const ArticleLink = styled(Link)`
  text-decoration: none;
  color: var(--color-body);
  p {
    transition: color 0.25s ease;
  }
  &:hover {
    text-decoration: none;
    p {
      color: #000000;
      transition: color 0.25s ease;
    }
    h3 {
      text-decoration: underline;
    }
  }
  h3 {
    color: var(--color-heading);
  }
  time,
  small {
    display: block;
  }
`

const ArticleCard = props => {
  const fluidImage = props.image.childImageSharp.fluid

  return (
    <ArticleLink to={props.to}>
      <ArticleCardElement key={props.id}>
        <Stack>
          <Img
            fluid={fluidImage}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={`${props.title} - image`}
          />
          <h3>{props.title}</h3>
          <time dateTime={props.date}>{props.date}</time>
          <p>{props.excerpt}</p>
          <small>Time to read: {props.timeToRead} minutes</small>
        </Stack>
      </ArticleCardElement>
    </ArticleLink>
  )
}

export default ArticleCard
