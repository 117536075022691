export const mediaRatingBands = {
  bad: 6,
  good: 7,
  great: 8,
  fire: 9,
  incredible: 9.5,
}

export const getRatingIcon = (rating: number) => {
  let icon = "🍆"
  if (rating < mediaRatingBands.good) {
    return (icon = "💩")
  }
  if (rating >= mediaRatingBands.good && rating < mediaRatingBands.great) {
    return (icon = "😐")
  }
  if (rating >= mediaRatingBands.great && rating < mediaRatingBands.fire) {
    return (icon = "😁")
  }
  if (rating >= mediaRatingBands.fire && rating < mediaRatingBands.incredible) {
    return (icon = "🔥")
  }
  return icon
}
