
import React from "react";
import styled from 'styled-components';
import { CardBase } from './CardBase';
import Img from "gatsby-image"
import { Link } from "gatsby";
import Stack from '../Stack/Stack';

const Card = styled(CardBase)`
    .gatsby-image-wrapper {
        width: 100%;
    }
    img {
        width: 100%;
    }
`

const ArticleLink = styled(Link)`
    text-decoration: none;
    color: var(--color-body);

    time, small {
            display: block;
        }

    &:hover {
        text-decoration: none;
        h3 {
            text-decoration: underline;
        }

        p, time {
            color: #000000;
        }
    }
`


const FeaturedCard = ({ excerpt, fields, frontmatter, title, timeToRead }) => (
    <ArticleLink to={fields.slug} title={`Link to read article ${frontmatter.title}`}>
        <Card>
            <Stack>
                <h2>{title}</h2>
                <Img
                    fluid={frontmatter.image.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={frontmatter.title} />
                <h3>{frontmatter.title}</h3>
                <time dateTime={frontmatter.date}>{frontmatter.date}</time>
                <p>{excerpt}</p>
                <small>Time to read: {timeToRead} minutes</small>
            </Stack>
        </Card>
    </ArticleLink>
)


export default FeaturedCard;