import { useContext, useEffect } from "react"
import { AppContext, AppContextProviderValue } from "../contexts/AppContext"
import {
  SET_MEDIA_ITEMS,
  SET_MEDIA_ITEMS_LOADING,
  SET_MEDIA_ITEMS_LOADING_ERROR,
} from "../contexts/AppContext.consts"
import { MediaItemDto } from "../types/MediaItemDto"
import { useLocalStorage } from "./useLocalStorage"

type useMediaItemsProps = {}

export type useMediaItems = {
  mediaItems: MediaItemDto[] | undefined
  isLoading: boolean
  hasError: boolean
  fetchItems: () => Promise<void>
}

export const useMediaItems = (): useMediaItems => {
  const { state, dispatch } = useContext<AppContextProviderValue>(AppContext)
  const [token] = useLocalStorage<string>("ss_token", "")

  const fetchItems = async () => {
    dispatch({
      type: SET_MEDIA_ITEMS_LOADING,
      data: true,
    })

    try {
      const response = await fetch("/api/GetMediaItems", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const values = await response.json()

      dispatch({
        type: SET_MEDIA_ITEMS,
        data: values,
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: SET_MEDIA_ITEMS_LOADING_ERROR,
        data: true,
      })
    }
  }

  useEffect(() => {
    console.log(state)
    if (!state.mediaJournal.mediaItems) {
      fetchItems()
    }
  }, [])

  return {
    mediaItems: state.mediaJournal.mediaItems,
    isLoading: state.mediaJournal.isLoadingMediaItems,
    hasError: state.mediaJournal.hasError,
    fetchItems,
  }
}
