import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import Carousel from "./HeroCarousel"
import Container from "../Container"
import { TextButton } from "../Button/TextButton"
import Seo from "../seo"
import Stack from "../Stack/Stack"
import AboutMe from "../AboutMe/AboutMe"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Title = styled.h1`
  display: block;
  font-size: 4rem;
  @media (min-width: 768px) {
    width: 55vw;
    font-size: 5.55rem;
  }
  @media (min-width: 1200px) {
    width: 40vw;
  }
`

const SubTitle = styled.h2`
  color: var(--color-body);
  font-size: 2.5rem;
  @media (min-width: 768px) {
    font-size: 4.16rem;
    text-align: left;
  }
`

const Wrapper = styled(Container)`
  width: 100%;
  display: flex;
  padding-top: var(--space-l);
  align-items: center;
  @media (min-width: 768px) {
    padding-top: var(--space-xl);
  }
  .content {
    padding-right: var(--space-m);
    .fluid {
      display: block !important;
    }
    @media (min-width: 1200px) {
      width: 55%;
      .fluid {
        display: none !important;
      }
    }
  }
  .img-wrapper {
    flex: 1;
    .fixed {
      display: none !important;
    }
    @media (min-width: 1200px) {
      width: 45%;
      .fixed {
        display: block !important;
      }
    }
  }
`

const IntroParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 2.5rem;
  @media (min-width: 768px) {
    font-size: 2rem;
    max-width: 55ch;
  }
`

const CarouselWrapper = styled.div`
  width: 100%;
  display: block;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * + * {
    margin-top: var(--space-l);
  }
  @media (min-width: 768px) {
    flex-direction: row;
    & > * + * {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
`

const HeroContainer = () => {
  const heading = "Scott Schubert"
  const [isShowingPhotos, setIsShowingPhotos] = useState(false)
  const [isShowingIntro, setIsShowingIntro] = useState(null)
  const imageRef = useRef(null)
  const wrapperRef = useRef(null)

  useEffect(() => {
    gsap.to(imageRef.current, {
      translateY: "-100px",
      scrollTrigger: {
        trigger: imageRef.current,
        start: 0,
        end: "+=800",
        scrub: true,
      },
    })
  }, [imageRef, wrapperRef])

  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "scott-transparent.png" }) {
            childImageSharp {
              id
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        const fluidImage = data.file.childImageSharp.fluid
        const fixedImage = data.file.childImageSharp.fixed

        const toggleShowPhotos = () => {
          setIsShowingPhotos(!isShowingPhotos)
        }

        const toggleShowIntro = () => {
          setIsShowingIntro(!isShowingIntro)
        }

        const readMoreText = `${isShowingIntro ? "Hide" : "Read"} more about me`
        const seeMoreText = `${
          isShowingPhotos ? "Hide" : "See some of"
        } my photos`

        return (
          <>
            <Seo title="Scott Schubert, Web Developer" />
            <Wrapper as="section" ref={wrapperRef}>
              <div className="content">
                <Stack topMarginSize="xl">
                  <Stack topMarginSize="m">
                    <Title>{heading}</Title>
                    <SubTitle>Web Developer</SubTitle>
                  </Stack>
                  <Img
                    className="fluid"
                    style={{ width: "100%", maxWidth: "500px" }}
                    alt="Scott Schubert, web developer"
                    fluid={fluidImage}
                  />
                  <Stack topMarginSize="l">
                    <IntroParagraph>
                      Hi, my name is Scott Schubert. I am a Web Developer from
                      Adelaide, South Australia.
                    </IntroParagraph>
                    <IntroParagraph>
                      I have over 5 years experience creating great websites and
                      applications using a variety of technologies including
                      React, Angular 2+ and NodeJS.
                    </IntroParagraph>
                    <ButtonWrapper>
                      <TextButton size="big" onClick={toggleShowPhotos}>
                        {seeMoreText}
                      </TextButton>
                      <TextButton size="big" onClick={toggleShowIntro}>
                        {readMoreText}
                      </TextButton>
                    </ButtonWrapper>
                  </Stack>
                </Stack>
              </div>
              <div ref={imageRef} className="img-wrapper">
                <Img
                  className="fixed"
                  alt="Scott Schubert, web developer"
                  fixed={fixedImage}
                />
              </div>
            </Wrapper>

            <AboutMe isShowingIntro={isShowingIntro}></AboutMe>

            {isShowingPhotos ? (
              <CarouselWrapper>
                <Carousel />
              </CarouselWrapper>
            ) : null}
          </>
        )
      }}
    />
  )
}

export default HeroContainer
